/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/frontend.scss';

// start the Stimulus application
import './bootstrap';


import $ from "jquery";

import "bootstrap";

import "./components/seomar/assets/js/plugins.js";

import "./components/seomar/assets/js/ajax-mail.js";

import "./components/seomar/assets/js/main.js";

window.WOW = require('wowjs').WOW;

new WOW({live: false}).init();

